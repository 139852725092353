section#ourManifest {
  height: 38rem;
  display: flex;
  align-items: center;
  justify-content: center;

  /*background-image: url('../../assets/lxa-header-3.jpg');*/
    background-color: #dee4e4;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 57% center;

  .section-content {
    width: 100%;
      padding: 0 2rem;
      text-align: center;

    h3:first-of-type {
      margin-top: 0;
    }
  }

  .emphasize {
    position: relative;
    display: inline-block;
    color: #fff;

    >span {
      z-index: 1;
      position: inherit;
      padding: 0.25rem;
      font-style: italic;
    }

    &::after {
      content: '';
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--brand-purple);
    }
  }

  @media screen and (min-width: 30rem) {
    background-position: 53% center;
  }

  @media screen and (min-width: 50rem) {
    background-position: center;

    .section-content {
      padding: 0 10%;
    }
  }
}
#contact {
  position: relative;
  background: url('../../assets/contact_bg.jpg') no-repeat center;
  background-size: cover;

  .section-content {
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-title {
    margin-bottom: 1rem;

    transition: all .3s ease;
  }

  .contact-content {
    list-style: none;
    width: 100%;
    max-width: 80%;
    text-align: left;

    transition: all .3s ease;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }

    .icon {
      display: inline-block;
      width: 24px;
      margin-right: 1rem;
    }

    >li {
      margin-bottom: 1rem;
    }
  }

  .overlay {
    transition: all .3s ease;
  }

  @media screen and (min-width: 50rem) {
    .section-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .contact-content {
      opacity: 0;
      visibility: hidden;
      transform: translateY(1rem);
    }

    &:hover {
      .section-title {
        opacity: 0;
        visibility: hidden;
      }

      .contact-content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .overlay {
        opacity: .5;
      }
    }
  }
}
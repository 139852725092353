.jobs {
  margin: 2rem 0;

  .title {
    margin-bottom: 1rem;
  }

  .job-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1rem;

    .job-card {
      flex: 100%;
      flex-grow: 0;
      background-color: #fff;
      border-radius: .5rem;
      box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05);

      transition: all .2s ease;

      >a {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #000;
        text-decoration: none;

        transition: all .2s ease;
      }

      .job-title {
        margin: 0;
      }

      .job-meta {
        display: flex;
        flex-direction: column;

        .job-readmore {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (min-width: 50rem) {
    .job-list {
      gap: 2rem;

      .job-card {
        flex: calc(50% - 1rem) 0;
      }
    }
  }
}
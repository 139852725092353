section#influencers {
  .influencer-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .influencer-grid--item {
      position: relative;
      width: 50%;
      padding-bottom: 50%;

      .influencer-grid--item-content {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 0 .5rem 12.5%;
        color: #fff;

        .title {
          margin-bottom: .5rem;
          font-size: 1rem;
          text-align: center;
          text-transform: uppercase;
        }
      }

      .influencer-grid--item-bg {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        transition: all .3s ease;
      }
    }
  }

  .section-title-wrapper {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background: url('../../assets/sanne1.jpg') center no-repeat;
    background-size: cover;
    pointer-events: none;

    transition: all .3s ease;

    .section-title {
      z-index: 1;
      color: #fff;
    }

    &::after { // Overlay
      content: '';
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .25);
    }
  }

  @media screen and (min-width: 50rem) {
    .section-title-wrapper {
      opacity: 1;
      visibility: visible;
      display: flex;
    }

    .influencer-grid--item {
      &:hover {
        .overlay {
          opacity: .5;
        }
      }
    }

    &:hover {
      .section-title-wrapper {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
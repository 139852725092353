.menu-wrapper {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
}
#siteMenu {
  z-index: 2;
  pointer-events: all;
  position: relative;
  width: calc(100vw - 4rem);
  height: 100%;
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  button.close-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    opacity: 0;
    visibility: visible;

    transition: all .1s ease;
    transition-delay: none;

    > span {
      position: absolute;
      width: 66.66%;
      height: 2px;
      background-color: #fff;
      opacity: .5;

      &:nth-child(1) {
        transform: rotate(45deg);
        transform-origin: top left;
        top: 25%;
        left: 25%;
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
        transform-origin: bottom left;
        bottom: 25%;
        left: 25%;
      }
    }
  }

  .menu {
    padding: 0;
    list-style: none;
    font-size: 2em;
    margin-bottom: 12vh;

    > .menu-item {
      margin: 0;
      padding: 0;

      a {
        display: block;
        padding: .5rem 0;
        color: #fff;
        text-decoration: none;
        cursor: pointer;

        transition: all .3s ease;

        &:hover {
          opacity: .5;
          transform: translateX(1rem);
        }
      }
    }
  }

  .sub-menu {
    padding: 0;
    list-style: none;
    font-size: 1em;
    margin-bottom: 12vh;

    > .submenu-item {
      margin: 0;
      padding: 0;
      
      a {
        display: block;
        padding: .25rem 0;
        color: #fff;
        text-decoration: none;

        transition: all .3s ease;

        &:hover {
          opacity: .5;
          transform: translateX(.5rem);
        }
      }
    }
  }
}

body.menu-open {
  .menu-wrapper {
    pointer-events: all;
  }
  #siteMenu {
    button.close-menu {
      opacity: 1;
      visibility: visible;

      transition-delay: 500ms;
    }
  }
}

@media screen and (min-width: 50rem) {
  #siteMenu {
    width: 30rem;
    justify-content: flex-start;

    button.close-menu {
      right: 1rem;
    }

    .menu {
      margin-bottom: unset;
      margin-top: 12vh;
    }
    .sub-menu {
      margin-bottom: unset;
      margin-top: 6vh;
    }
  }
}
:root {
  --swiper-navigation-size: 22px;
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
}

.swiper {

  .swiper-button-next,
  .swiper-button-prev {
    top: unset;
    left: unset;
    bottom: 1rem;
    color: #fff;
    opacity: .75;
  }

  .swiper-button-next {
    right: 1rem;
  }

  .swiper-button-prev {
    right: 2.5rem;
  }

  .swiper-pagination {
    width: 33.33%;
    left: 1rem;
    bottom: 1rem;
    text-align: left;
  }
}

#heroSwiper {
  z-index: 1;
  position: relative;

  .swiper-tagline {
    z-index: 2;
    position: absolute;
    width: auto;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    font-size: 1em;
  }

  .swiper {
    .slide-content {
      z-index: 3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .slide-title {
        margin: 0;
        font-size: 2rem;
      }

      &[data-color='light'] {

        .slide-title,
        .slide-url {
          color: #fff;
        }
      }

      &[data-color='dark'] {

        .slide-title,
        .slide-url {
          color: #000;
        }
      }
    }

    .slide-overlay {
      content: '';
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, .25);

      &[data-color='dark'] {
        background-color: rgba(255, 255, 255, .25);
      }
    }

    .slide-bg {
      z-index: 1;
      height: 100%;
      min-height: 70vh;
      max-height: 90vh;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 50rem) {
  .swiper {
    .swiper-button-next {
      right: 2rem;
    }

    .swiper-button-prev {
      right: 3.5rem;
    }

    .swiper-pagination {
      left: 2rem;
    }
  }
}
.site-footer {
  padding: 2rem 0;
  color: #d2d2d2;
  background-color: #111;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #d2d2d2;
      text-decoration: underline;
    }
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .footer-copyright {
    font-size: .75em;
  }
  .footer-logo {
    display: block;
    width: 6rem;
    margin-bottom: .5rem;
  }
  .footer-socials {
    a {
      margin-right: .5rem;
      opacity: 1;

      &:active,
      &:hover {
        opacity: .75;
      }
    }
  }
  .footer-menu {
    ul {
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: .25rem;
        }
      }
    }
  }

  @media screen and (min-width: 50rem) {
    padding: 4rem 0;

    .footer-content {
      flex-direction: row;
      
      > * {
        width: 25%;
      }
    }
  }
}
#vision {
  position: relative;
  background: url('../../assets/vision_bg.jpg') no-repeat center;
  background-size: cover;

  .section-content {
    width: 75%;
    max-width: 500px;
    margin: 0 auto;
  }

  .section-title {
    margin-bottom: 1rem;

    transition: all .3s ease;
  }

  .section-body {
    transition: all .3s ease;
  }

  .overlay {
    transition: all .3s ease;
  }

  @media screen and (min-width: 50rem) {
    .section-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;
    }
    
    .section-body {
      opacity: 0;
      visibility: hidden;
      transform: translateY(1rem);
    }

    &:hover {
      .section-title {
        opacity: 0;
        visibility: hidden;
      }

      .section-body {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .overlay {
        opacity: .5;
      }
    }
  }
}
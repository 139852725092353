#ecosystem {
  background: url('../../assets/ecosystem1.jpg') no-repeat center;
  background-size: cover;

  .section-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .section-title {
      color: #fff;

      transition: all .3s ease;
    }
  }

  .ecosystem-graphic {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all .3s ease;

    img {
      width: 75%;
      max-width: 800px;

      transition: all .3s ease;
    }
  }

  .overlay {
    transition: all .3s ease;
  }

  @media screen and (min-width: 50rem) {
    .ecosystem-graphic {
      position: absolute;
      opacity: 0;
      visibility: 0;
    }
    &:hover {
      .section-content {
        .section-title {
          opacity: 0;
          visibility: hidden;
        }
      }
      .ecosystem-graphic {
        opacity: 1;
        visibility: visible;

        img {
          transform: scale(1.1);
        }
      }
      .overlay {
        opacity: .5;
      }
    }
  }
}
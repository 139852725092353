$header-height: 4rem;
$header-height--stuck: 3rem;

.site-header {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: $header-height;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: unset;

  transition: height .3s ease;

  >a {
    color: #fff;
  }

  .site-header--logo {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: height .3s ease;

    cursor: pointer;

    svg {
      display: block;
      width: auto;
      height: 1.5rem;

      transition: all .3s ease;

      path {
        fill: #fff;
      }
    }
  }
        .site-header--menu {
          margin-right: 2rem;
    
          a {
            color: white;
          }
        }

  .site-header--btn {
    margin-right: 1rem;
  }

  .site-menu-btn {
    transition: height .3s ease, padding .3s ease;
  }

  &.stuck {
    height: $header-height--stuck;
    background-color: var(--brand-purple);
    mix-blend-mode: normal;

    .site-header--logo {
      height: $header-height--stuck;

      svg {
        height: 1.25rem;

        path {
          fill: #fff;
        }
      }
    }

    .site-menu-btn {
      padding-top: .875rem;
      padding-bottom: .875rem;

      span {
        background-color: #fff;
      }
    }

    .site-header {
      height: $header-height--stuck;
    }
  }
}

body.job,
body.not-found {
  .site-header:not(.stuck) {
    .site-header--logo {
      svg {
        path {
          fill: #000;
        }
      }
    }

    >a {
      color: #000;
    }
  }
}

@media screen and (min-width: 50rem) {
  .site-header {
    .site-header--logo {
      margin-left: 2rem;
    }
    .site-menu-btn {
      margin-right: 1rem;
    }
    .site-header--btn {
      margin-right: 2rem;
    }
  }
}
// Variables
:root {
  --bg-color-1: #fff;
  --bg-color-2: #f2f2f2;
  --brand-purple: #b863eb;
}

// Helpers
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

// Typography

@font-face {
  font-family: 'Actay Wide';
  src: url('./assets/font/ActayWide-Bold.eot');
  src: url('./assets/font/ActayWide-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/ActayWide-Bold.woff2') format('woff2'),
    url('./assets/font/ActayWide-Bold.woff') format('woff'),
    url('./assets/font/ActayWide-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Actay Condensed';
  src: url('./assets/font/ActayCondensed-Thin.eot');
  src: url('./assets/font/ActayCondensed-Thin.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/ActayCondensed-Thin.woff2') format('woff2'),
    url('./assets/font/ActayCondensed-Thin.woff') format('woff'),
    url('./assets/font/ActayCondensed-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Actay Wide';
  src: url('./assets/font/ActayWide-BoldItalic.eot');
  src: url('./assets/font/ActayWide-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/ActayWide-BoldItalic.woff2') format('woff2'),
    url('./assets/font/ActayWide-BoldItalic.woff') format('woff'),
    url('./assets/font/ActayWide-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Actay';
  src: url('./assets/font/Actay-RegularItalic.eot');
  src: url('./assets/font/Actay-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Actay-RegularItalic.woff2') format('woff2'),
    url('./assets/font/Actay-RegularItalic.woff') format('woff'),
    url('./assets/font/Actay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Actay';
  src: url('./assets/font/Actay-Regular.eot');
  src: url('./assets/font/Actay-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Actay-Regular.woff2') format('woff2'),
    url('./assets/font/Actay-Regular.woff') format('woff'),
    url('./assets/font/Actay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Actay Condensed';
  src: url('./assets/font/ActayCondensed-ThinItalic.eot');
  src: url('./assets/font/ActayCondensed-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/ActayCondensed-ThinItalic.woff2') format('woff2'),
    url('./assets/font/ActayCondensed-ThinItalic.woff') format('woff'),
    url('./assets/font/ActayCondensed-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

html {
  font-size: 81.25%; // 13px

  @media screen and (min-width: 50rem) {
    font-size: 100%; // 16px
  }
}

body {
  font-family: 'Actay';
  font-weight: normal;
  line-height: 1.75;
  overflow-x: hidden;
}

p {
  margin-bottom: 1em;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Actay Wide';
  font-weight: bold;
  line-height: 1.3;
}

h1,
.h1,
h2,
.h2 {
  text-transform: uppercase;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2,
.h2 {
  font-size: 2.441rem;
}

h3,
.h3 {
  font-size: 1.953rem;
}

h4,
.h4 {
  font-size: 1.563rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

small {
  font-size: 0.8rem;
}

// Global

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  //overflow-x: hidden;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .site-main {
    flex-grow: 1;
  }
}

a {
  color: #000;
  display: inline-block;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  padding-left: 1rem;
  
  > li {
    padding-left: .5rem;
    margin-bottom: 1rem;
  }
}

.icon {
  width: 32px;
}

address {
  display: inline;
  font-style: normal;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  outline: none;
  border: none;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .875em;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-underline-offset: 0.5em;
  padding: .125rem 0;

  transition: all .3s ease;

  &:active,
  &:hover {
    opacity: .5;
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 1rem;

  &.small {
    max-width: 900px;
  }

  @media screen and (min-width: 50rem) {
    padding: 0 2rem;
  }
}

section {
  overflow: hidden;

  >* {
    z-index: 2;
  }

  .overlay {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .33;
  }

  .section-content {
    text-align: center;

    p {
      margin: 0;
    }
  }

  .section-title {
    margin-top: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  @media screen and (min-width: 50rem) {
    .section-title {
      margin: 0;
    }
  }
}

.eco-inf {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  >section {
    position: relative;
    padding-bottom: 100%;
    width: 100%;
  }

  @media screen and (min-width: 50rem) {
    >section {
      width: 50%;
      padding-bottom: 50%;
    }
  }
}

.vis-con {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  >section {
    position: relative;
    padding-bottom: 100%;
    width: 100%;

    .section-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  @media screen and (min-width: 50rem) {
    >section {
      width: 50%;
      padding-bottom: 50%;
    }
  }
}

body {
  background-color: var(--bg-color-2);
}

#careers {
  .page-header {
    position: relative;
    height: 300px;
    background: url('../../assets/careers_header.jpg') no-repeat;
    background-size: cover;
    background-position: center 17%;
    display: flex;
    align-items: center;
    justify-content: center;

    .page-title {
      z-index: 2;
      color: #fff;
    }
  }
}
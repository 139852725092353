body {
    background-color: var(--bg-color-2);
}

#ethical {
    .page-header {
        position: relative;
        height: 300px;
        background-color: #c779f4;
        display: flex;
        align-items: center;
        justify-content: center;

        .page-title {
            z-index: 2;
            color: #fff;
        }

        .page-content {
            text-align: center;
        }
    }
}

section#ethical-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;


    .section-content {
        width: 100%;
        padding: 0 1rem;
        text-align: left;

        h3:first-of-type {
            margin-top: 0;
        }
    }



    .emphasize {
        position: relative;
        display: inline-block;
        color: #fff;

        >span {
            z-index: 1;
            position: inherit;
            padding: 0.25rem;
            font-style: italic;
        }

        &::after {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--brand-purple);
        }
    }

    @media screen and (min-width: 30rem) {
        background-position: 53% center;
    }

    @media screen and (min-width: 50rem) {
        background-position: center;

        .section-content {
            padding: 0 10%;
        }
    }
}

#sign-last-row {
    margin-top: 10px;
    font-weight: 800;
}

#first-row-ethical {
    margin-bottom: 10px;
}